import React from "react";
import NavStatic from "../containers/navStatic/NavStatic";
import Footer from "../components/footer/Footer";
import Layout from "../components/layout";
import "./about.css";

const About = () => {
  return (
    <Layout>
      <NavStatic />
      <div className="about-section_bg ">
        <div className="about-section_container ">
          <h2 className="about-header_title">About Us</h2>
          <p className="about-header_subTitle ">
            Sizify was founded with the purpose of making shopping for apparel
            online easier. Our mission is to make guessing proper clothing fit
            online a thing of the past. Shoppers and merchants around the world
            use Sizify to make apparel shopping more enjoyable.
          </p>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default About;
